<template>
  <div>
    <div class="three-list">
      <div class="three-list-item" v-for="(item, index) in commentData" :key="index">
        <div class="three-list-item-header flex-nowrap">
          <img v-if="item.is_official && item.source === 'admin'" src="~assets/images/data/js.jpg" class="three-list-item-header-img" alt="" />
          <img v-else :src="item.user.avatar" class="three-list-item-header-img" alt="" />
          <div class="three-list-item-header-right">
            <div class="list-item-content space-between">
              <div class="list-item-content-left" :class="item.is_official && item.source === 'admin' ? 'list-item-content-left-admin' : ''">
                {{ item.is_official && item.source === 'admin' ? '讲师' : item.user.nickname }} <span v-if="item.is_anonymous">（匿名）</span>
              </div>
              <div class="list-item-content-right">
                来自于 <span v-if="item.source === 'app'">APP</span>
                <span v-else-if="item.source === 'platform'">中台</span>
                <span v-else-if="item.source === 'admin'">后台</span>
                {{ item.created_at_humans }}
              </div>
            </div>
          </div>
        </div>
        <div class="three-list-item-content">
          {{ item.content }}
        </div>
        <div class="three-list-item-footer">
          <el-tag effect="dark" :class="item.is_top === true ? 'dark-gray' : 'dark-orange'" style="cursor: pointer" @click="topping(item.id)">置顶</el-tag>
          <el-tag class="dark-blue" style="cursor: pointer" v-if="item.status === 0" @click="hidden(item.id)">显示</el-tag>
          <!-- <el-tag class="dark-gray" style="cursor: pointer" v-if="item.status === 1" @click="hidden(item.id)">隐藏</el-tag>-->
          <el-dropdown trigger="click" @command="handleCommand" style="cursor: pointer">
            <span class="el-dropdown-link">
              <img src="~assets/images/index/mores.png" class="moresimg" alt="" style="vertical-align: middle;" />
              <img src="~assets/images/index/mores-h.png" class="moresimgh" alt="" style="vertical-align: middle;" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <div class="">
                <!-- <el-dropdown-item :command="['reply', item]" v-if="!item.child">回复</el-dropdown-item> -->
                <el-dropdown-item :command="['reply', item]">回复</el-dropdown-item>
              </div>
              <el-dropdown-item :command="['del', item]">删除</el-dropdown-item>
              <!-- <div class="" v-if="comment_isreply && item.status == 1"> -->
            </el-dropdown-menu>
          </el-dropdown>
          <span v-if="item.child">
            <el-button type="text" class="call" @click="showReplyUp(index)" v-if="index === reply_index">
              收起回复
              <img src="~assets/images/index/up.png" alt="" class="btm-img" />
              <img src="~assets/images/index/up-h.png" alt="" class="btm-img-h" />
            </el-button>
            <el-button type="text" class="call" @click="showReply(index, item)" v-else>
              展开回复
              <img src="~assets/images/index/btm.png" alt="" class="btm-img" />
              <img src="~assets/images/index/btm-h.png" alt="" class="btm-img-h" />
            </el-button>
          </span>
        </div>
        <!-- <div class="reply"  v-for="(childItem, inx) in childrenList" :key="inx"> -->
        <div class="reply" v-if="item.child && reply_index === index" v-loading="loadingList">
          <div v-for="(childItem, inx) in childrenList" :key="inx">
            <div class="reply-top space-between">
              <div class="reply-top-left">
                <img v-if="childItem.is_official && childItem.source === 'admin'" src="~assets/images/data/js.jpg" class="three-list-item-header-img" alt="" />
                <img v-else :src="childItem.user.avatar" class="three-list-item-header-img" alt="" />
                <div class="list-item-content-left" :class="childItem.is_official && childItem.source === 'admin' ? 'list-item-content-left-admin' : ''">
                  <span class="over_hidden"> {{ childItem.is_official && childItem.source === 'admin' ? '讲师' : childItem.user.nickname }}</span>
                  <span v-if="childItem.is_anonymous">（匿名）</span>
                </div>
                <span style="margin:0 10px 0 10px; font-size: 14px;" v-if="getUuid(childItem)">回复</span>
                <div class="list-item-content-left" :class="childItem.parent_user_id === 0 ? 'list-item-content-left-admin' : ''">
                  <span class="over_hidden"> {{ childItem.parent_user_id === 0 ? '讲师' : childItem.parent_user.nickname }}</span> <span v-if="childItem.parent.is_anonymous">（匿名）</span>
                </div>
              </div>

              <!-- <div class="reply-top-left" v-if="item.child && item.child.admin_id == 0">[讲师回复]</div>
            <div class="reply-top-left" v-else>[官方回复]</div> -->
              <!-- <div class="reply-top-right" @click="delInteract(item)">删除</div> -->
              <div>
                <!-- <el-tag effect="dark" :class="childItem.is_top === true ? 'dark-gray' : 'dark-orange'" style="cursor: pointer" @click="topping(childItem.id)">置顶</el-tag>
                <el-tag class="dark-blue" style="cursor: pointer" v-if="childItem.status === 0" @click="hidden(childItem.id)">显示</el-tag> -->
                <el-dropdown trigger="click" @command="handleCommand" style="cursor: pointer">
                  <span class="el-dropdown-link">
                    <img src="~assets/images/index/mores.png" class="moresimg" alt="" style="vertical-align: middle;" />
                    <img src="~assets/images/index/mores-h.png" class="moresimgh" alt="" style="vertical-align: middle;" />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="['reply', childItem]">回复</el-dropdown-item>
                    <el-dropdown-item :command="['del', childItem]">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="reply-btm">{{ childItem.content }}</div>
            <el-divider class="chid-divider" v-if="inx + 1 !== childrenList.length"></el-divider>
          </div>
        </div>

        <el-divider></el-divider>
      </div>
    </div>
    <!--    新增修改弹框-->
    <el-dialog class="dialog" title="回复评价" @close="cancelDialog" :visible.sync="isDialogReply" top="calc( 50vh - 150px )" width="540px" :destroy-on-close="true" :append-to-body="true">
      <!-- 表单-->
      <el-input type="textarea" :rows="4" placeholder="请输入回复内容" v-model="content"> </el-input>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="cancelDialog">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="confirmReply">确 定</el-button>
      </span>
    </el-dialog>
    <!--    删除弹框-->
    <el-dialog
      class="delDiolog pldelDiolog"
      :visible.sync="delDialogReply"
      @close="delDialog"
      top="calc( 50vh - 220px )"
      icon="el-icon-warning"
      :destroy-on-close="true"
      :append-to-body="true"
      width="360px"
      center
    >
      <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
      <span>你确定要删除该评价么？</span>
      <div class="delDiolog-item">
        <el-radio v-model="reason" @change="reasonFun" label="垃圾广告">垃圾广告</el-radio>
      </div>
      <div class="delDiolog-item">
        <el-radio v-model="reason" @change="reasonFun" label="不实信息">不实信息</el-radio>
      </div>
      <div class="delDiolog-item">
        <el-radio v-model="reason" @change="reasonFun" label="辱骂、人身攻击等行为">辱骂、人身攻击等行为</el-radio>
      </div>
      <div class="delDiolog-item">
        <el-radio v-model="reason" @change="reasonFun" label="other">其他原因</el-radio>
      </div>
      <el-input
        type="textarea"
        :rows="2"
        v-model="reason_other"
        maxlength="100"
        placeholder="请输入原因"
        v-if="reason == 'other'"
        @blur="reason_other ? (isComment_show = false) : (isComment_show = true)"
      >
      </el-input>
      <p v-if="reason == 'other' && isComment_show" style="text-align: left;color: #F01414">请填写原因</p>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="delDialog">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" v-if="confirm_reply_status" @click="delReply">确 定</el-button>
        <el-button class="form-operation-item" round type="primary" v-else :loading="true">提交中</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'comment',
  props: ['comment', 'type', 'uuid', 'isreply'],
  data() {
    return {
      commentData: [], // 数组数据
      comment_type: '', // 类型
      comment_uuid: '', // uuid
      comment_isreply: false, // 可否回复
      isDialogReply: false, // 回复弹框
      delDialogReply: false, // 删除弹框
      content: '', // 回复内容
      current_info: {}, // 当前点击的
      childrenList: [],
      reason: '垃圾广告', // 删除绑定信息
      reason_other: '', // 输入其他原因
      confirm_reply_status: true, // 回复提交状态
      del_hierarchy: '',
      reply_index: -1, // 评论下标
      isComment_show: false,
      plId: '',
      loadingList: false,
    };
  },
  created() {
    if (this.comment) {
      this.commentData = this.comment;
    }
    if (this.type) {
      this.comment_type = this.type;
    }
    if (this.uuid) {
      this.comment_uuid = this.uuid;
    }
    if (this.isreply) {
      this.comment_isreply = this.isreply;
    }
  },
  watch: {
    comment() {
      this.commentData = this.comment;
    },
    uuid() {
      this.comment_uuid = this.uuid;
    },
    type() {
      this.comment_type = this.type;
    },
    isreply() {
      this.comment_isreply = this.isreply;
    },
  },
  mounted() {},
  methods: {
    getUuid(item) {
      return item?.parent_user?.uuid || true;
    },
    // 取消回复弹框
    cancelDialog() {
      this.isDialogReply = false;
      this.content = '';
    },
    delDialog() {
      this.delDialogReply = false;
      this.reason = '垃圾广告';
      this.reason_other = '';
    },
    // 获取当前选择
    handleCommand(command) {
      this.current_info = command[1];
      if (this.reply_index === -1) {
        this.plId = this.current_info.id;
      }
      if (command[0] === 'del') {
        this.del_hierarchy = 'first';
        this.delDialogReply = true;
      } else if (command[0] === 'reply') {
        this.isDialogReply = true;
      }
    },
    // 讲师回复删除
    delInteract(data) {
      this.del_hierarchy = 'second';
      this.current_info = data;
      this.delDialogReply = true;
    },
    // 确认提交
    confirmReply: _.debounce(function() {
      if (!this.confirm_reply_status) {
        return false;
      }
      this.confirm_reply_status = false;
      let url = this.$api.Comments;
      let data = {
        type: this.comment_type,
        uuid: this.comment_uuid,
        parent_id: this.current_info.id,
        content: this.content,
      };
      this.$http
        .post(url, data, true)
        .then((res) => {
          if (res.data.success) {
            this.$message.success('回复成功');
            this.cancelDialog();
            this.getChildrenList(this.plId);
            this.$emit('commentsHandle', 'upload');

            //this.getComments()
            setTimeout(() => {
              this.confirm_reply_status = true;
            }, 1500);
          } else {
            this.confirm_reply_status = true;
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.confirm_reply_status = true;
          this.$message.error(error.response.data.message);
        });
    }, 300),
    // 获取当前的选项
    reasonFun(value) {
      this.reason_other = '';
    },
    // 删除当前评论
    delReply() {
      if (this.reason == 'other' && this.reason_other == '') {
        this.isComment_show = true;
        return;
      }
      let url = this.$api.Comments + '/' + this.current_info.id + '/' + this.$api.Delete;
      if (this.del_hierarchy === 'second') {
        url = this.$api.Comments + '/' + this.current_info.child.id + '/' + this.$api.Delete;
      }
      let data = {
        reason: this.reason,
      };
      if (this.reason_other.trim() != '') {
        data.reason = this.reason_other;
      }
      this.$http
        .post(url, data, true)
        .then((res) => {
          if (res.data.success) {
            this.delDialog();

            //this.getComments()
            this.$message.success('删除成功');
            this.getChildrenList(this.plId);
            this.$emit('commentsHandle', 'upload');
            // if (this.reason_other === -1) {
            //   this.$emit('commentsHandle', 'upload');
            // } else {
            //   this.getChildrenList(this.plId);
            // }
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
    // 点击当前展开
    showReply(index, item) {
      this.reply_index = index;
      this.plId = item.id;
      this.getChildrenList(this.plId);
    },
    // 获取回复列表
    getChildrenList(id) {
      this.loadingList = true;
      let url = this.$api.comments + `/${id}/` + this.$api.children;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.childrenList = res.data.data.data;
          this.loadingList = false;
        }
      });
    },
    // 收起回复
    showReplyUp() {
      this.reply_index = -1;
    },
    //显示隐藏
    hidden(id) {
      this.$confirm('这是您操作比较重要的数据, 你确定要公开该数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let url = this.$api.comments + `/${id}` + this.$api.changeStatus;
          this.$http.get(url, true).then((res) => {
            if (res.data.success) {
              if (this.reply_index !== -1) {
                this.getChildrenList(this.plId);
              }
              this.$emit('commentsHandle', 'upload');
            }
          });
        })
        .catch(() => {});
    },
    //置顶
    topping(id) {
      let url = this.$api.comments + `/${id}/` + this.$api.ChangeTop;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.$emit('commentsHandle', 'upload');
          if (this.reply_index !== -1) {
            this.getChildrenList(this.plId);
          }
          this.$message.success('修改成功');
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.three-list {
  color: #93999f;
  .load {
    margin-top: 20px;
    .loadmore {
      margin-left: 10px;
    }
  }
  .list-item-content-left {
    font-size: 14px;
    font-weight: 400;
    color: #1c1f21;
    // max-width: 100px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .list-item-content-left-admin {
    color: #81d8d0;
  }
  .three-list-item {
    border-radius: 6px;
    margin-bottom: 20px;
    .three-list-item-header {
      .three-list-item-header-img {
        border: 1px solid #ebeff2;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .three-list-item-header-right {
        width: 100%;
        margin-left: 20px;
        display: inline-block;
        .list-item-title {
          font-size: 14px;
          font-weight: 400;
          color: #1c1f21;
        }
        .list-item-content {
          font-size: 14px;
          font-weight: 400;
          color: #93999f;
          line-height: 32px;

          .list-item-content-right {
            font-size: 12px;
            //color: #008AFF;
          }
        }
      }
    }
    .three-list-item-content {
      font-size: 14px;
      font-weight: 400;
      color: #71777d;
      margin: 20px 0;
      word-break: break-all;
    }
    .reply {
      padding: 20px;

      background: rgba(156, 160, 164, 0.06);
      border-radius: 6px;
      // margin-top: 15px;
      .chid-divider {
        margin: 15px 0;
      }
      .reply-top {
        margin-bottom: 10px;
        .three-list-item-header-img {
          margin-right: 20px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
        .reply-top-left {
          display: flex;
          align-items: center;
          .list-item-content-left {
            display: flex;
            color: #1c1f21;
            .over_hidden {
              display: inline-block;
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .list-item-content-left-admin {
            display: flex;
            color: #81d8d0;
          }
        }
        // .reply-top-right {
        //   font-size: 14px;
        //   font-weight: 400;
        //   color: #71777d;
        //   cursor: pointer;
        // }
      }
      .reply-btm {
        font-size: 14px;
        font-weight: 400;
        color: #71777d;
      }
    }
    .three-list-item-footer {
      text-align: end;
      font-size: 12px;
      margin-bottom: 20px;

      .display {
        background: #71777d;
        border-radius: 9px;
        color: #fff;
        height: 20px;
        line-height: 20px;
        cursor: pointer;
        border: unset;
        margin: 0 20px 0 10px;
      }
      .more {
        cursor: pointer;
      }
      .btm-img {
        width: 12px;
        height: 7px;
        margin-bottom: 1px;
      }
      .btm-img-h {
        width: 12px;
        height: 7px;
        margin-bottom: 1px;
      }
      .call {
        font-size: 14px;
        font-weight: 400;
        color: #008aff;
        padding: 0;
        padding-left: 20px;
        margin-left: 30px;
        border-left: 1px solid #f0f1f5;
        .btm-img-h {
          display: none;
        }
      }
      .call:hover {
        color: #045ca8;
        .btm-img {
          display: none;
        }
        .btm-img-h {
          display: inline-block;
        }
      }
    }
  }
}
.dark-orange {
  background: #ffa509;
  border-radius: 9px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  border: unset;
}
.dark-gray {
  background: #b7bbbf;
  border-radius: 9px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  border: unset;
}
.dark-blue {
  background: #008aff;
  border-radius: 9px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  border: unset;
}
.dark-orange:hover {
  background: #f18800;
}
.dark-blue:hover {
  background: #045ca8;
}
.dark-gray:hover {
  background: #71777d;
}
.el-tag {
  margin-right: 20px;
}
//删除弹框
.delDiolog {
  /deep/ .el-dialog {
    width: 360px;
    border-radius: 10px;
  }
  .dioTitle {
    padding: 28px 0 20px;
    height: 16px;
    font-size: 16px;
    font-weight: bold;
    color: #1c1f21;
  }
  .delDiolog-item {
    text-align: left;
    margin: 20px 0;
  }
  /deep/ .el-dialog__body {
    padding: 0 20px 44px 20px;
    font-size: 12px;
    font-weight: 400;
    color: #71777d;
    text-align: center;
  }
}
/deep/ .el-radio__label {
  color: #71777d;
  font-weight: 400;
}
.el-dropdown-link {
  .moresimgh {
    display: none;
  }
}
.el-dropdown-link:hover {
  .moresimg {
    display: none;
  }
  .moresimgh {
    display: inline-block;
  }
}
/deep/ .el-dialog__title {
  font-size: 16px;
  font-weight: bold;
  color: #1c1f21;
}
//表单操作按钮
.form-operation {
  width: 300px;
  .form-operation-item {
    width: 120px;
    height: 42px;
  }
  /deep/ .el-button + .el-button {
    margin-left: 26px;
  }
  /deep/ .el-button {
    color: #93999f;
    border: 1px solid #b7bbbf;
  }
  /deep/ .el-button:hover,
  .el-button:focus {
    color: #71777d;
    border-color: #93999f;
    background-color: rgba(147, 153, 159, 0.1);
  }
  /deep/ .el-button--primary {
    color: #fefeff;
    border: unset;
  }
  /deep/ .el-button--primary:hover,
  .el-button--primary:focus {
    background: #65c5bc;
    border-color: #65c5bc;
    color: #ffffff;
  }
}
.dialog {
  /deep/ .el-dialog {
    border-radius: 10px;
  }
  /deep/ .el-dialog__header {
    padding: 15px 0 16px 20px;
    border-bottom: 1px solid #ebeff2;
    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
      color: #1c1f21;
    }
  }
  /deep/ .el-dialog__footer {
    padding: 0 20px 40px;
    text-align: center;
  }
}
</style>
